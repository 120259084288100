.MintSection {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column nowrap;
  margin: 0 auto;
  width: 100%;
  height: 85vh;
  background-color: #ECEFEF;
}

.mint-countdown {
  padding: 5px;
}

.alert {
  padding: 15px;
  background-color: #2a6a72; /* Red */
  color: white;
  margin: 15px;
  font-weight: 700;
}

.alert-address {
  display: flex;
  padding-top: 5px;
  color: rgb(199, 199, 199);
  font-size: 14px;
}

.supply {
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 25px;
  font-weight: 900;
}

.MintContainer {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-flow: row wrap;
  height: 85%;
  width: 85%;
  margin: 0 auto;
}

.Web3Container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column nowrap;
  font-family: 'TeXGyreHeroes';
  background-color: #3d3c3c;
  color:#FFFFFF;
  padding: 40px;
  border-radius: 25px;
}

.MintTextContainer h2 {
  font-size: 45px;
  text-align: center;
}

.CheckWalletContainer {
  padding-top: 1.5rem;
  text-align: center;
}

.CheckWalletContainer button {
  margin: 0.3rem;
  font-size: 1rem;
  font-family: 'TeXGyreHeroes';
  color: #051A20;
  padding: 1rem;
  background-color: #FFFFFF;
  border: 2px solid #051A20;
  box-shadow: none;
  border-radius: 0.5rem;
}

.CheckWalletContainer button {
  cursor: pointer;
}

.CheckWalletContainer p {
  padding-top: 1rem;
  font-size: 1rem;
  /*text-align: center;*/
}

.PrerevealContainer img {
  height: 200px;
  border-radius: 50%;
  pointer-events: none;
}

.MintGradientContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ECEFEF;
  width: 100%;
  height: 15%;
}

.MintGradientContainer img {
  width: 100%;
  height: 100%;
  transform: scaleY(-1);
}

/* Media Queries */

@media (min-width: 800px) {
  .PrerevealContainer img {
    height: 350px;
  }
}

hr.new1 {
  border-top: 1px solid red;
}

.MintButton {
  text-align: left;
}

.mint-heading {
  font-weight: bold;
}

.mint-button-lower {
  text-align: center;
}