.MenuContainer img {
  height: 50px;
  width: 45px;
  user-select: none;
  cursor: pointer;
}

.MenuContainer a {
  font-family: 'TeXGyreHeroesBold';
  text-transform: uppercase;
  font-size: 1.1rem;
  color: #F1F2EB;
  cursor: pointer;
}

.MenuIconContainer {
  display: flex;
}

.MenuIconContainer img {
  height: 36px;
  width: 36px;
  user-select: none;
  pointer-events: none;
}

.MenuIconContainer a {
  padding: 1rem;
}

.MenuWrapper {
  position: fixed;
  top: 15vh;
  left: 0;
  width: 100vw;
  height: 85vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column nowrap;
  background: rgba(0, 0, 0, .95);
}

.MenuWrapper a {
  padding: 1rem;
}

.MenuWrapper {
  animation: fadeIn .7s;
  -webkit-animation: fadeIn .7s;
  -moz-animation: fadeIn .7s;
  -o-animation: fadeIn .7s;
  -ms-animation: fadeIn .7s;
}

@keyframes fadeIn {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

@-webkit-keyframes fadeIn {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

@-moz-keyframes fadeIn {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

@-o-keyframes fadeIn {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

@-ms-keyframes fadeIn {
  0% {opacity: 0;}
  100% {opacity: 1;}
}
