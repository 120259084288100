.HomeSection {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column nowrap;
  margin: 0 auto;
  width: 100%;
  height: 85vh;
  user-select: none;
}

.TitleContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column nowrap;
  width: 85%;
  height: 85%;
  margin: 0 auto;
}

.TitleContainer img {
  height: 150px;
}

.TitleContainer h2 {
  font-size: 45px;
  font-family: 'TeXGyreHeroesBold';
  text-align: center;
  padding-top: 50px;
}

.GradientContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ECEFEF;
  width: 100%;
  height: 15%;
}

.GradientContainer img {
  width: 100%;
  height: 100%;
}

.countdown {
  padding: 15px;
  background-color: #6f868a; /* Red */
  color: white;
  margin: 15px;
  font-weight: 700;
  text-align: center;
  margin-top: 30px;
  border-radius: 10px;
  a {
    color: #fff;
  }
}

.btn {
  cursor: pointer;
  &.btn-primary {
    background-color: #2a6a72;
    border-color: #2a6a72;
    &:hover, &:active, &:focus {
      background-color: darken(#2a6a72, 10%) !important;
      border-color: darken(#2a6a72, 10%) !important;
    }
  }
}


.section {
  &.home-section {
    padding: 150px 0px;
    .logo {
      width: 400px;
      padding: 20px;
      max-width:100%;
    }
  }
  &.mint-box-section {
    margin-bottom: 150px;
    position: relative;
    .container {
      background: #efeff3;
      padding: 100px 50px;
      border-radius: 20px;
    }
    .mint-box-wrap {
      background: #e0e0e0;
      padding: 40px;
      border-radius: 20px;
      .alert {
        background: #2a6a72;
        color: #fff;
        margin-bottom: 40px;
      }
      .connect-wallet-wrap {
        text-align: center;
      }
    }
    .rain-pencil {
      width: 400px;
      max-width:100%;
      border-radius: 100px;
    }
    .logo {
      padding: 30px;
      width: 200px;
      max-width:100%;
    }
    .success-fireworks {
      pointer-events: none;
      width: 530px;
      position: absolute;
      top: 40px;
    }
  }
}