/* FinalSix */
@font-face {
  font-family: 'FinalSix';
  src: url(../assets/fonts/FinalSix.otf) format("truetype");
}
@font-face {
  font-family: 'FinalSixBold';
  src: url(../assets/fonts/FinalSixBold.otf) format("truetype");
}

/* TeXGyreHeroes */
@font-face {
  font-family: 'TeXGyreHeroes';
  src: url(../assets/fonts/texgyreheros-regular.otf) format("truetype");
}
@font-face {
  font-family: 'TeXGyreHeroesBold';
  src: url(../assets/fonts/texgyreheros-bold.otf) format("truetype");
}

@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

body {
  font-family: 'TeXGyreHeroes', 'FinalSix', 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1rem;
  margin: 0;
  padding: 0;
  background-color: #FFFFFF;
  color: #051A20;
  width: 100vw;
  overflow-x: hidden;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
}

body::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

a {
  text-decoration: none;
}

html, body {
  scroll-behavior: smooth;
  max-width: 100%;
  overflow-x: hidden;
}
