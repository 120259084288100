.FooterSection {
  margin: 0 auto;
  position: relative;
  width: 100%;
  height: 15vh;
}

.FooterContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  width: 85%;
  height: 100%;
}

.FooterContainer p {
  font-family: 'TexGyreHeroesBold';
  font-size: 0.7rem;
}

.FooterContainer img {
  display: none;
  pointer-events: none;
}

.IconFooterContainer {
  display: flex;
}

.IconFooterContainer img {
  display: flex;
  height: 24px;
  width: 24px;
}

.IconFooterContainer a {
  margin: 4px;
}

/* Media Queries */

@media (min-width: 800px) {
  .FooterContainer img {
    display: inline;
    height: 40%;
  }
  .FooterContainer p {
    font-size: 0.8rem;
  }
  .IconFooterContainer {
    display: none;
  }
}
