.NavSection {
  margin: 0 auto;
  position: relative;
  width: 100%;
  height: 15vh;
  user-select: none;
}

.NavContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  width: 85%;
  height: 100%;
}

.LogoContainer {
  background: url('../assets/transparent-wave.svg');
  background-repeat: no-repeat;
  height: 50px;
  width: 100px;
  position: relative;
}

.LogoContainer::before {
  content: '';
  background: url('../assets/gradient-wave.svg');
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  position: absolute;
  opacity: 0;
  transition: opacity .5s;
}

.LogoContainer:hover::before {
  opacity: 1;
}

.MenuContainer {
  display: flex;
}

.NavIconContainer {
  display: none;
}

.NavIconContainer img {
  height: 36px;
  width: 36px;
}

.NavIconContainer img:hover {
  transition: all .3s ease;
  transform: scale(1.1);
}

.NavIconContainer a {
  margin: 5px;
}

/* Media Queries */

@media (min-width: 800px) {
  .MenuContainer {
    display: none;
  }
  .NavIconContainer {
    display: flex;
  }
}
